<template>
  <div class="page" id="reorganize">
    <el-row :gutter="10">
      <el-col :span="showList ? 4 : 1" :class="['p_r', showList ? '' : 'el-col-unfold']">
        <div :class="['unfoldClassifyBtn', showList ? 'el-icon-arrow-left':'el-icon-arrow-right']"
             @click="showList = !showList"></div>
        <div class="leftHeighe bg-white" v-show="showList">
          <el-descriptions title="藏品分类"></el-descriptions>
          <div class="flex_b_c">
            <el-input size="small" placeholder="输入关键字进行过滤" clearable v-model="filterText"
                      @input="filterTreeData"></el-input>
          </div>
          <el-tree v-loading="treeLoading" node-key="id" ref="tree" :data="treeData" class="treeDateCls"
                   show-checkbox :filter-node-method="filterNode"
                   :props="defaultProps" @check="selRecord">
            <div slot-scope="{ node, data }">
              <el-tooltip class="item" effect="dark" :content="node.label" placement="top-start">
                <div class="omit">{{ node.label }}</div>
              </el-tooltip>
            </div>
          </el-tree>
        </div>
      </el-col>
      <el-col :span="showList ? 20 : 24" :class="[showList ? '' : 'el-col-unfold','leftHeighe']">
        <div class="text_center noData" v-if="!archivesBasicDataId.length">请选择左侧档案分类</div>
        <div v-else>
          <div class="bg-white">
            <el-form size="small" class="query-form dynamicForm" ref="inputForm" :model="inputForm"
                     @keyup.enter.native="getDataList(1,1,1)"
                     :rules="rules" label-width="150px">
              <el-form-item label="藏品名称" prop="collectionName">
                <el-input v-model.trim="inputForm.collectionName"
                          placeholder="请输入藏品名称(限50字)"
                          maxlength="50" clearable></el-input>
              </el-form-item>
              <el-form-item label="藏品编号" prop="helpNum">
                <el-input v-model.trim="inputForm.helpNum"
                          placeholder="请输入藏品编号(限50字)"
                          maxlength="50"
                          clearable>
                </el-input>
              </el-form-item>
              <el-form-item label="完残程度" prop="integrity">
                <el-select v-model="inputForm.integrity"
                           placeholder="请选择完残程度"
                           style="width: 100%"
                           clearable>
                  <el-option
                      v-for="item in integrityList"
                      :key="item.id"
                      :label="item.levelName"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="完残情况" prop="integrityInfo">
                <el-input v-model="inputForm.integrityInfo"
                          placeholder="请输入完残情况(限50字)"
                          maxlength="50" clearable></el-input>
              </el-form-item>
              <el-form-item label="藏品卡" prop="carded">
                <el-select v-model="inputForm.carded"
                           placeholder="请选择" style="width: 100%"
                           clearable>
                  <el-option
                      v-for="item in generateList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label-width="0" :prop="item.code"
                            v-for="(item, index) in config"
                            :key="index" v-if="item.dataWhere == 0">
                <div class="flex_b_c metadata">
                  <el-tooltip :disabled="item.overflow" class="item" effect="dark" :content="item.basicName"
                              placement="top">
                    <div class="omit text_right metadataName" ref="configName">{{ item.basicName }}</div>
                  </el-tooltip>
                  <div class="metadataCont">
                    <!--字符-->
                    <el-input v-model.trim="inputForm[item.code]" :maxlength="item.maxLength"
                              :placeholder="item.forName ? '请输入' + item.forName : '请输入' + item.basicName"
                              clearable
                              v-if="item.archivesBasicTypeCode == 'text' && !item.archivesBasicMultiselectList.length"></el-input>
                    <!--数值、浮点数-->
                    <el-input v-model.trim="inputForm[item.code]"
                              :maxlength="item.archivesBasicTypeCode == 'integer'? '8':'11'"
                              @input="getNumType(item)"
                              :placeholder="item.forName ? '请输入' + item.forName : '请输入' + item.basicName"
                              clearable
                              v-if="item.archivesBasicTypeCode == 'integer' || item.archivesBasicTypeCode == 'double'"></el-input>
                    <el-select class="w100i" v-model="inputForm[item.code]"
                               :multiple="item.archivesBasicMultiselectList[0].selectBy == 1" collapse-tags
                               :placeholder="item.forName ? '请选择' + item.forName : '请选择' + item.basicName"
                               clearable
                               v-if="item.archivesBasicMultiselectList.length">
                      <el-option v-for="(v,i) in item.archivesBasicMultiselectList" :key="i"
                                 :label="v.archivesBasicValue"
                                 :value="v.archivesBasicValue"></el-option>
                    </el-select>
                    <!--时间-->
                    <!--时间-->
                    <el-date-picker class="w100i"
                                    v-model="inputForm[item.code]" value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    type="datetime" clearable placeholder="请选择"
                                    v-if="item.archivesBasicTypeCode == 'date' && item.dataFormat == 'yyyy-MM-dd HH:mm:ss'">
                    </el-date-picker>
                    <el-date-picker class="w100i"
                                    v-model="inputForm[item.code]" value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd"
                                    type="date" clearable placeholder="请选择"
                                    v-if="item.archivesBasicTypeCode == 'date' && item.dataFormat == 'yyyy-MM-dd' || item.archivesBasicTypeCode == 'date' && !item.dataFormat">
                    </el-date-picker>
                    <el-date-picker class="w100i"
                                    v-model="inputForm[item.code]" value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyyMMdd"
                                    type="date" clearable placeholder="请选择"
                                    v-if="item.archivesBasicTypeCode == 'date' && item.dataFormat == 'yyyyMMdd'">
                    </el-date-picker>
                  </div>
                </div>
              </el-form-item>
            </el-form>
            <div class="flex_b_c">
              <div>
                <el-button size="small" type="primary" icon="el-icon-search" @click="getDataList(1,1,1)">
                  查询
                </el-button>
                <el-button size="small" icon="el-icon-refresh-right" @click="resetting()">重置</el-button>
              </div>
              <div>
                <el-button type="primary" icon="el-icon-search" size="small" @click="advancedSearch()">
                  高级检索
                </el-button>
                <el-button size="small"
                           icon="el-icon-loading"
                           v-show="hasPermissionButton('collection:accounts:card:batchGenerate')"
                           v-noMoreClick
                           @click="generate(0,1)">批量生成
                </el-button>
                <el-button size="small"
                           icon="el-icon-printer"
                           v-show="hasPermissionButton('collection:accounts:card:batchPrint')"
                           v-noMoreClick
                           @click="print(1)">批量打印
                </el-button>
              </div>
            </div>
          </div>

          <div class="bg-white" style="margin-top: 10px">
            <el-table
                :data="dataList"
                size="small"
                v-loading="loading"
                ref="multipleTable"
                height="calc(100vh - 350px)"
                class="table"
                :row-key="'id'"
                @sort-change="sortChange"
                @selection-change="selectionChangeHandle"
            >
              <el-table-column :reserve-selection="true" type="selection" width="50"/>
              <el-table-column label="序号" type="index" width="50"></el-table-column>
              <el-table-column prop="carded" label="藏品卡" show-overflow-tooltip>
                <template slot-scope="scope">
                  {{ scope.row.carded == 0 ? '已生成' : '未生成' }}
                </template>
              </el-table-column>
              <el-table-column prop="name" label="图标" width="120px">
                <template slot-scope="scope">
                  <div class="flex_a_c">
                    <div v-if="scope.row.carded == 0"
                         class="icon-wenwukapian iconfont listIcon"></div>
                    <el-image
                        style="width: 36px; height: 36px"
                        :src="scope.row.picMasterViewUrl"
                        :fit="'cover'"
                        :preview-src-list="[scope.row.picMasterViewUrl]"
                    >
                      <div slot="error" class="image-slot">
                        <el-image
                            style="width: 36px; height: 36px"
                            :src="require('@/assets/img/default.png')"
                            :fit="'cover'">
                        </el-image>
                      </div>
                    </el-image>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="collectionName" label="藏品名称"
                               show-overflow-tooltip></el-table-column>
              <el-table-column prop="helpNum" sortable min-width="120px" label="辅助账编号"
                               show-overflow-tooltip></el-table-column>
              <el-table-column prop="generalNum" sortable min-width="120px" label="总账编号"
                               show-overflow-tooltip></el-table-column>
              <el-table-column prop="integrityInfo" label="完残情况"
                               show-overflow-tooltip></el-table-column>
              <el-table-column prop="integrity" label="完残程度" show-overflow-tooltip></el-table-column>
              <el-table-column prop="cStoreState" label="库存状态" show-overflow-tooltip>
                <template slot-scope="scope">
                  {{ $dictUtils.getDictLabel("cStore_state", scope.row.cStoreState, '-') }}
                </template>
              </el-table-column>
              <el-table-column v-for="(item, index) in config" :key="item.archivesBasicId"
                               :prop="item.code"
                               :sortable="item.archivesBasicTypeCode == 'date' || item.archivesBasicTypeCode == 'integer' ? 'custom' : false"
                               :label="item.basicName" v-if="item.dataSelect == 0" min-width="130"
                               show-overflow-tooltip>
                <template slot="header" slot-scope="scope">
                  <el-tooltip class="item" effect="dark" :content="item.forName ? item.forName : item.basicName"
                              placement="top-start">
                    <span class="omit">{{ item.forName ? item.forName : item.basicName }}</span>
                  </el-tooltip>
                </template>
                <template slot-scope="scope">
                  <span
                      v-if="item.archivesBasicTypeCode == 'date'">{{
                      metadataDateFormatValue(item, scope.row.archivesData[item.code])
                    }}</span>
                  <span v-else>{{ scope.row.archivesData[item.code] }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="createUserName" label="创建者"
                               show-overflow-tooltip></el-table-column>
              <el-table-column prop="createDate" sortable label="创建时间" width="150px">
                <template slot-scope="scope">
                  {{ scope.row.createDate | formatDate }}
                </template>
              </el-table-column>
              <el-table-column prop="updateUserName" label="最后更新人"
                               show-overflow-tooltip></el-table-column>
              <el-table-column prop="updateDate" sortable label="最后更新时间" width="150px"
                               show-overflow-tooltip>
                <template slot-scope="scope">
                  {{ scope.row.updateDate | formatDate }}
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="操作" width="180">
                <template slot-scope="scope">
                  <el-button v-if="scope.row.carded == 0" size="mini"
                             type="text" @click="preview(scope.row)"
                             v-show="hasPermissionButton('collection:accounts:card:preview')">预览
                  </el-button>
                  <el-button size="mini" type="text"
                             @click="generate(0,scope.row)"
                             v-if="scope.row.carded != 0"
                             v-show="hasPermissionButton('collection:accounts:card:generate')">生成
                  </el-button>
                  <el-button v-if="scope.row.carded == 0" size="mini"
                             type="text" @click="print(2,scope.row)"
                             v-noMoreClick
                             v-show="hasPermissionButton('collection:accounts:card:print')">打印
                  </el-button>
                  <el-button v-if="scope.row.carded == 0" size="mini"
                             type="text" @click="maintenance(scope.row)"
                             v-show="hasPermissionButton('collection:accounts:card:maintenance')">打印维护
                  </el-button>
                  <el-button v-if="scope.row.carded == 0" size="mini"
                             type="text" @click="detail(scope.row)"
                             v-show="hasPermissionButton('collection:accounts:card:detail')">元素详情
                  </el-button>
                  <el-button size="mini" type="text"
                             @click="generate(1,scope.row)"
                             v-if="scope.row.carded == 0"
                             v-show="hasPermissionButton('collection:accounts:card:deleteF')">删除藏品卡
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="text_center">
              <el-pagination
                  @size-change="sizeChangeHandle"
                  @current-change="currentChangeHandle"
                  :current-page="pageNo"
                  :page-sizes="[10, 20, 50, 100]"
                  :page-size="pageSize"
                  :total="total"
                  background
                  layout="total, sizes, prev, pager, next, jumper"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <AdvancedSearch ref="advancedSearch" @getDataList="getSrhCondition"></AdvancedSearch>
    <el-dialog
        title="元素详情"
        :visible.sync="dialogVisible"
        width="700px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="handleClose">
      <el-table
          :data="lopDataList"
          size="small"
          height="400px"
          class="table"
      >
        <el-table-column label="序号" type="index" width="50"></el-table-column>
        <el-table-column prop="name" label="元素名称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="xLate" label="X坐标" show-overflow-tooltip></el-table-column>
        <el-table-column prop="yLate" label="Y坐标" show-overflow-tooltip></el-table-column>
        <el-table-column prop="font" label="字体" show-overflow-tooltip></el-table-column>
        <el-table-column prop="height" label="高度" show-overflow-tooltip></el-table-column>
        <el-table-column prop="width" label="宽度" show-overflow-tooltip></el-table-column>
        <el-table-column prop="siex" label="大小" show-overflow-tooltip></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import AdvancedSearch from "@/components/advancedSearch/advancedSearch.vue";
import getLodop from '@/utils/lodop'

export default {
  name: "collectionCard",
  components: {AdvancedSearch},
  data() {
    return {
      treeData: [],
      treeLoading: false,
      filterText: '',
      defaultProps: {
        children: 'children',
        label: 'archivesBasicName'
      },
      retract: true,
      config: [],
      inputForm: {},
      rules: {},
      archivesBasicDataId: [],
      showList: true,

      loading: false,
      dataList: [],
      dataListSelect: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,

      advancedList: [],
      searchForm: {
        whereList: [],
        sortArchivesDataElasticsearch: [],
      },

      generateList: [
        {
          value: 0,
          label: '已生成'
        },
        {
          value: 1,
          label: '未生成'
        },
      ],

      reapetList: [],
      LODOP: null,
      dialogVisible: false,
      lopDataList: [
        {
          yLate: '57px',
          xLate: '97px',
          width: '75px',
          name: '级别',
          height: '20px',
          font: '宋体',
          siex: '9px',
        },
        {
          yLate: '57px',
          xLate: '653px',
          width: '75px',
          name: '方位',
          height: '20px',
          font: '宋体',
          siex: '9px',
        },
        {
          yLate: '94px',
          xLate: '117px',
          width: '155px',
          name: '总帐号',
          height: '20px',
          font: '宋体',
          siex: '9px',
        },
        {
          yLate: '94px',
          xLate: '330px',
          width: '155px',
          name: '原来号',
          height: '20px',
          font: '宋体',
          siex: '9px',
        },
        {
          yLate: '123px',
          xLate: '118px',
          width: '366px',
          name: '名称',
          height: '20px',
          font: '宋体',
          siex: '9px',
        },
        {
          yLate: '123px',
          xLate: '562px',
          width: '155px',
          name: '质地',
          height: '20px',
          font: '宋体',
          siex: '9px',
        },
        {
          yLate: '153px',
          xLate: '117px',
          width: '155px',
          name: '时代',
          height: '20px',
          font: '宋体',
          siex: '9px',
        },
        {
          yLate: '153px',
          xLate: '330px',
          width: '155px',
          name: '年代',
          height: '20px',
          font: '宋体',
          siex: '9px',
        },
        {
          yLate: '153px',
          xLate: '562px',
          width: '155px',
          name: '征集、发掘日期',
          height: '20px',
          font: '宋体',
          siex: '9px',
        },
        {
          yLate: '183px',
          xLate: '117px',
          width: '155px',
          name: '作者',
          height: '20px',
          font: '宋体',
          siex: '9px',
        },
        {
          yLate: '183px',
          xLate: '330px',
          width: '155px',
          name: '数量',
          height: '20px',
          font: '宋体',
          siex: '9px',
        },
        {
          yLate: '183px',
          xLate: '562px',
          width: '155px',
          name: '来源',
          height: '20px',
          font: '宋体',
          siex: '9px',
        },
        {
          yLate: '213px',
          xLate: '117px',
          width: '155px',
          name: '尺寸',
          height: '20px',
          font: '宋体',
          siex: '9px',
        },
        {
          yLate: '213px',
          xLate: '330px',
          width: '155px',
          name: '重量',
          height: '20px',
          font: '宋体',
          siex: '9px',
        },
        {
          yLate: '213px',
          xLate: '562px',
          width: '155px',
          name: '征集、发掘人',
          height: '20px',
          font: '宋体',
          siex: '9px',
        },
        {
          yLate: '242px',
          xLate: '117px',
          width: '366px',
          name: '款识',
          height: '20px',
          font: '宋体',
          siex: '9px',
        },
        {
          yLate: '242px',
          xLate: '562px',
          width: '366px',
          name: '入藏日期',
          height: '20px',
          font: '宋体',
          siex: '9px',
        },
        {
          yLate: '270px',
          xLate: '117px',
          width: '366px',
          name: '现状描述',
          height: '142px',
          font: '宋体',
          siex: '9px',
        },
        {
          yLate: '270px',
          xLate: '562px',
          width: '155px',
          name: '如何入藏',
          height: '20px',
          font: '宋体',
          siex: '9px',
        },
        {
          yLate: '300px',
          xLate: '562px',
          width: '155px',
          name: '金额',
          height: '20px',
          font: '宋体',
          siex: '9px',
        },
        {
          yLate: '330px',
          xLate: '562px',
          width: '155px',
          name: '照片号',
          height: '20px',
          font: '宋体',
          siex: '9px',
        },
        {
          yLate: '360px',
          xLate: '562px',
          width: '155px',
          name: '有关文件号',
          height: '20px',
          font: '宋体',
          siex: '9px',
        },
        {
          yLate: '420px',
          xLate: '117px',
          width: '366px',
          name: '鉴定意见',
          height: '20px',
          font: '宋体',
          siex: '9px',
        },
        {
          yLate: '390px',
          xLate: '563px',
          width: '155px',
          name: '备注',
          height: '52px',
          font: '宋体',
          siex: '9px',
        },
      ],

      leaveList: [],
      integrityList: [
        {
          id: '残缺',
          levelName: '残缺',
        },
        {
          id: '基本完整',
          levelName: '基本完整',
        },
        {
          id: '完整',
          levelName: '完整',
        },
        {
          id: '严重残缺',
          levelName: '严重残缺',
        },
      ],
    }
  },

  mounted() {
    this.searchTree()
    this.getSelectData()
  },

  methods: {
    // 查询树状数据
    searchTree() {
      this.treeData = [
        {
          id: '',
          archivesBasicName: '全部',
          children: [],
        },
      ]
      this.treeLoading = true
      this.$axios(this.api.collection.selectArchivesBasicData, {}, 'post').then((res) => {
        if (res.status) {
          this.treeData[0].children = res.data
          //全选
          this.$nextTick(() => {
            this.getTreeId(this.treeData)
            this.$refs.tree.setCheckedKeys(this.archivesBasicDataId);
            this.selRecord('', '')
          })
        }
        this.treeLoading = false
      })
    },

    matchedData(value, data) {
      if (data && data.length != 0) {
        let setRow = data.filter(item => {
          return item.level == value
        })
        return setRow[0].levelName
      }
    },

    getSelectData() {
      this.$axios(this.api.collection.listLevelSelect).then(data => {
        if (data.status) {
          this.leaveList = data.data
        }
      })
    },

    //循环取树形控件id
    getTreeId(data) {
      if (data && data.length != 0) {
        data.forEach(item => {
          this.archivesBasicDataId.push(item.id)
          if (item.children) {
            this.getTreeId(item.children)
          }
        })
      } else {
        return
      }
    },

    // 获取元数据类型设置输入正则
    getNumType(item) {
      let regular = /[^\d]/g
      if (item.archivesBasicTypeCode == "double") {
        regular = /[^\d.]/g
      }
      this.$set(this.inputForm, item.code, this.inputForm[item.code].replace(regular, ''))
    },

    // 选择档案分类
    selRecord(data, checked) {
      //该节点所对应的对象、树目前的选中状态对象
      this.searchForm.sortArchivesDataElasticsearch = []
      if (checked) {
        this.archivesBasicDataId = []
        this.archivesBasicDataId = checked.checkedNodes.map(item => {
          return item.id
        })
      }
      if (this.archivesBasicDataId.length == 0) return
      let getByIdUrl = this.api.collection.basicdataGetById + '/' + this.archivesBasicDataId
      if (this.archivesBasicDataId.length > 1) {
        getByIdUrl = this.api.collection.getArchivesBasicByRequired
      }
      this.$axios(getByIdUrl).then(data => {
        if (data.status) {
          this.inputForm = {
            helpNum: '',
            collectionName: '',
            carded: '',
            integrity: '',
            integrityInfo: '',
          }
          this.rules = {}
          this.config = data.data.archivesBasicDataGroup
          this.config.map(item => {
            if (item.dataWhere == 0) {
              this.$set(item, 'value', '')
              this.$set(this.inputForm, item.code, '')
              if (item.archivesBasicTypeCode == 'integer') {
                this.$set(this.rules, item.code, [{
                  validator: this.validator.isDigits,
                  trigger: 'blur'
                }])
              }
            }
          })
          this.$nextTick(() => {
            this.textExceeds(this.config, 'configName')
          })
          this.getDataList(1, 1, 1)
        }
      })
    },

    //判断文字是否超出范围
    textExceeds(list, refName) {
      let configName = this.$refs[refName]
      if (!list || !list.length) return
      list.forEach((item, index) => {
        if (configName[index].scrollWidth > configName[index].clientWidth) {
          this.$set(item, 'overflow', false)
        } else {
          this.$set(item, 'overflow', true)
        }
      })
    },

    //获取列表数据
    getDataList(type, dividePage, archivesData) {
      this.$refs['inputForm'].validate((valid) => {
        if (valid) {
          if (type == 1) {
            this.pageNo = 1
          }
          if (dividePage == 1) {
            this.$refs.multipleTable.clearSelection()
          }
          if (archivesData == 1) {
            this.searchForm.sortArchivesDataElasticsearch = []
            this.$refs.multipleTable.clearSort()
          }
          this.loading = true
          let keys = Object.keys(this.inputForm)
          let values = Object.values(this.inputForm)
          let searchForm = JSON.parse(JSON.stringify(this.searchForm))
          keys.map((key, index) => {
            this.config.map(item => {
              if (item.code == key && values[index] != '' && values[index] != null) {
                if (item.archivesBasicTypeCode == 'text') {
                  // 多选遍历多选的值
                  if (Array.isArray(values[index])) {
                    values[index].map(v => {
                      searchForm.whereList.push({
                        archivesBasicTypeMethodCode: "%",
                        archivesBasicTypeCode: item.archivesBasicTypeCode,
                        key: item.code,
                        value: v
                      })
                    })
                  } else {
                    if (item.archivesBasicMultiselectList.length) {
                      searchForm.whereList.push({
                        archivesBasicTypeMethodCode: "=",
                        archivesBasicTypeCode: item.archivesBasicTypeCode,
                        key: item.code,
                        value: values[index]
                      })
                    } else {
                      searchForm.whereList.push({
                        archivesBasicTypeMethodCode: "%",
                        archivesBasicTypeCode: item.archivesBasicTypeCode,
                        key: item.code,
                        value: values[index]
                      })
                    }
                  }
                }
                if (item.archivesBasicTypeCode == 'integer') {
                  searchForm.whereList.push({
                    archivesBasicTypeMethodCode: "=",
                    archivesBasicTypeCode: item.archivesBasicTypeCode,
                    key: item.code,
                    value: values[index]
                  })
                }
                if (item.archivesBasicTypeCode == 'date') {
                  searchForm.whereList.push({
                    archivesBasicTypeMethodCode: ">=",
                    archivesBasicTypeCode: item.archivesBasicTypeCode,
                    key: item.code,
                    value: values[index] ? values[index][0] : ''
                  })
                  searchForm.whereList.push({
                    archivesBasicTypeMethodCode: "<=",
                    archivesBasicTypeCode: item.archivesBasicTypeCode,
                    key: item.code,
                    value: values[index] ? values[index][1] : ''
                  })
                }
                if (item.archivesBasicTypeCode == "double") {
                  searchForm.whereList.push({
                    archivesBasicTypeMethodCode: "=",
                    archivesBasicTypeCode: item.archivesBasicTypeCode,
                    key: item.code,
                    value: values[index]
                  })
                }
              }
            })
          })
          let fieldList = [
            {
              "archivesBasicTypeMethodCode": "=",
              "key": "entryState",
              "theKey": "",
              "value": "1"
            },
            {
              "archivesBasicTypeMethodCode": "=",
              "key": "processState",
              "theKey": "",
              "value": "3"
            },
          ]
          let archivesBasicDataId = this.archivesBasicDataId.filter(item => {
            return item == ''
          })
          if (archivesBasicDataId.length != 0) {
            archivesBasicDataId = null
          } else {
            archivesBasicDataId = this.archivesBasicDataId
          }
          let fieldOrList = []
          // if (this.inputForm.helpNum) {
          //     fieldOrList.push(
          //         {
          //             archivesBasicTypeMethodCode: "%",
          //             archivesBasicTypeCode:'text',
          //             key: 'helpNum',
          //             value: this.inputForm.helpNum
          //         },
          //         {
          //             archivesBasicTypeMethodCode: "%",
          //             archivesBasicTypeCode:'text',
          //             key: 'generalNum',
          //             value: this.inputForm.helpNum
          //         },
          //     )
          // }
          if (this.inputForm.carded === 0 || this.inputForm.carded) {
            fieldList.push({
              archivesBasicTypeMethodCode: "=",
              archivesBasicTypeCode: 'text',
              key: 'carded',
              value: this.inputForm.carded
            })
          }
          if (this.inputForm.collectionName) {
            fieldList.push(
                {
                  archivesBasicTypeMethodCode: "%",
                  archivesBasicTypeCode: 'text',
                  key: 'collectionName',
                  value: this.inputForm.collectionName
                },
            )
          }
          let fieldWhereList = []
          if (this.inputForm.integrity) {
            fieldWhereList.push(
                {
                  archivesBasicTypeMethodCode: "=",
                  archivesBasicTypeCode: 'text',
                  key: 'integrity',
                  value: this.inputForm.integrity
                },
            )
          }
          if (this.inputForm.integrityInfo) {
            fieldWhereList.push(
                {
                  archivesBasicTypeMethodCode: "=",
                  archivesBasicTypeCode: 'text',
                  key: 'integrityInfo',
                  value: this.inputForm.integrityInfo
                },
            )
          }
          this.$axios(this.api.collection.queryElasticsearchTemplatePage, {
            ...searchForm,
            fieldList: fieldList,
            fieldOrList,
            fieldWhereList,
            current: this.pageNo,
            size: this.pageSize,
            number: this.inputForm.helpNum,
            archivesBasicDataId: archivesBasicDataId,
          }, 'post').then((res) => {
            if (res.status) {
              this.dataList = res.data.records
              this.total = parseInt(res.data.total)
              if (this.dataList.length == 0 && this.pageNo > 1) {
                this.pageNo--
                this.getDataList()
              }
              this.$nextTick(() => {
                this.$refs.multipleTable.doLayout()
              })
            } else {
              this.$message.error('查询失败');
            }
            this.loading = false
          })
        }
      })
    },

    addData(num, row) {
      this.$router.push({
        path: '/collection/accounts/addCollection',
        query: {butType: 8, id: row.id, archivesBasicDataId: row.archivesBasicDataId}
      })
    },

    // 列表排序
    sortChange(column) {
      if (column.prop == 'createDate' || column.prop == 'updateDate') {
        this.searchForm.sortArchivesDataElasticsearch = [
          {
            archivesBasicTypeCode: 'date',
            isSys: 1,
            key: column.prop,
            sort: column.order == "descending" ? 'desc' : (column.order == "ascending" ? 'asc' : ''),
          }
        ]
      } else if (column.prop == 'helpNum' || column.prop == 'generalNum') {
        this.searchForm.sortArchivesDataElasticsearch = [
          {
            archivesBasicTypeCode: 'text',
            isSys: 1,
            key: column.prop,
            sort: column.order == "descending" ? 'desc' : (column.order == "ascending" ? 'asc' : ''),
          }
        ]
      } else {
        let sortItem = this.config.filter(item => {
          return item.code == column.prop
        })
        this.searchForm.sortArchivesDataElasticsearch = [
          {
            archivesBasicTypeCode: sortItem[0].archivesBasicTypeCode,
            isSys: 0,
            key: 'archivesData.' + column.prop,
            sort: column.order == "descending" ? 'desc' : (column.order == "ascending" ? 'asc' : ''),
          }
        ]
      }
      this.getDataList('', 1)
    },

    //审核记录
    record(row) {
      this.$refs.record.init(row.id)
    },

    //重置
    resetting() {
      this.$refs.inputForm.resetFields();
      this.searchForm.whereList = []
      this.advancedList = []
      this.getDataList(1, 1, 1)
    },

    // 高级检索
    advancedSearch() {
      this.$refs.advancedSearch.init(this.config, this.advancedList)
    },

    // 获取高级检索条件
    getSrhCondition(data) {
      if (data) {
        this.advancedList = data.advancedList
        this.searchForm.whereList = data.whereList
        this.getDataList(1, 1, 1)
      }
    },

    //num 0生成 1删除
    generate(num, row) {
      let ids = []
      if (row == 1) {
        if (this.dataListSelect.length == 0) {
          this.$message.warning('请至少选择一条数据')
          return
        }
        ids = this.dataListSelect.map(item => {
          if (item) {
            return item.id
          }
        })
      } else {
        ids = [row.id]
      }
      this.$confirm(`此操作将${num == 0 ? '生成' : '删除'}该藏品卡, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios(this.api.collection.batchCard, {
          ids: ids,
          state: num
        }, 'post').then((res) => {
          if (res.status) {
            if (num == 0) {
              this.$message.success('生成成功')
            } else {
              this.$message.success('删除成功')
            }
            this.getDataList('', 1)
          } else {
            this.$message.error(res.msg);
          }
          this.loading = false
        })
      })
    },

    //打印 1批量 2单个
    print(type, row) {
      let data = []
      if (type == 1) {
        if (this.dataListSelect.length == 0) {
          this.$message.warning('请至少选择一条数据')
          return
        }
        this.reapetList = this.dataListSelect.filter(item => {
          return item.carded == 1
        })
        data = this.dataListSelect.filter(item => {
          return item.carded == 0
        })
        if (data.length == 0) {
          this.$message.success(`打印失败，未生成藏品卡`)
          return;
        }
      } else {
        data = [row]
      }
      this.$confirm(`此操作将打印该藏品卡, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        data.forEach((item, index) => {
          //生成模板
          this.CreatePage(item, type, index, data.length)
        })
      })
    },

    //预览
    preview(row) {
      this.CreatePage(row);
      this.LODOP.PREVIEW();
    },

    maintenance(row) {
      this.CreatePage(row);
      this.LODOP.PRINT_DESIGN()
    },

    //元素详情
    detail(row) {
      this.dialogVisible = true
    },

    handleClose() {
      this.dialogVisible = false
    },

    //删除
    deleteF(row) {
      this.$confirm(`您是否确认删除数据?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios(this.api.collection.card, {
          id: row.id,
          state: 1,
        }, 'delete').then(data => {
          if (data && data.status) {
            this.$message.success('删除成功')
            this.getDataList('', 1)
          } else {
            this.$message.error(data.msg)
          }
        })
      })
    },

    //加载模板
    CreatePage(item, type, index, daNum) {
      //设计网址 http://demo.sinoverse.cn/lodop_print
      this.LODOP = getLodop('', '', this);
      this.LODOP.PRINT_INITA(0, 0, 840, 1188, "");
      this.LODOP.SET_PRINT_MODE("CATCH_PRINT_STATUS", true) //开启捕获打印状态
      this.LODOP.SET_PRINT_PAGESIZE(0, 2100, 2900, "");
      this.LODOP.ADD_PRINT_SETUP_BKIMG("http://10.10.10.233/theme/card.png");
      this.LODOP.SET_SHOW_MODE("BKIMG_LEFT", 0);
      this.LODOP.SET_SHOW_MODE("BKIMG_WIDTH", "201.08mm");
      this.LODOP.SET_SHOW_MODE("BKIMG_HEIGHT", "116.95mm");
      this.LODOP.SET_SHOW_MODE("BKIMG_PRINT", true);
      this.LODOP.ADD_PRINT_TEXT(48, 96, 75, 20, `${this.matchedData(item.collectionLevel, this.leaveList)}`); //级别
      this.LODOP.ADD_PRINT_TEXT(48, 615, 75, 20, "智弈达数智博物馆"); //方位
      this.LODOP.ADD_PRINT_TEXT(81, 119, 150, 20, `${item.generalNum ? item.generalNum : ''}`); //总帐号
      this.LODOP.ADD_PRINT_TEXT(81, 332, 150, 20, `${item.helpNum ? item.helpNum : ''}`); //分类号
      this.LODOP.ADD_PRINT_TEXT(81, 534, 150, 20, ""); //原来号
      this.LODOP.ADD_PRINT_TEXT(107, 119, 361, 20, `${item.collectionName ? item.collectionName : ''}`); //名称
      this.LODOP.ADD_PRINT_TEXT(107, 534, 150, 20, `${item.archivesData['zhidi'] ? item.archivesData['zhidi'] : ''}`); //质地
      this.LODOP.ADD_PRINT_TEXT(136, 119, 150, 20, ""); //时代
      this.LODOP.ADD_PRINT_TEXT(136, 332, 150, 20, `${item.archivesData['nianfen（nian）'] ? item.archivesData['nianfen（nian）'] : ''}`); //年代
      this.LODOP.ADD_PRINT_TEXT(136, 559, 125, 20, ""); //征集、发掘日期
      this.LODOP.ADD_PRINT_TEXT(161, 119, 150, 20, ""); //作者
      this.LODOP.ADD_PRINT_TEXT(161, 332, 150, 20, `${item.archivesData['shijishuliang（jian）'] ? item.archivesData['shijishuliang（jian）'] : ''}`); //数量
      this.LODOP.ADD_PRINT_TEXT(161, 534, 150, 20, `${item.archivesData['laiyuan'] ? item.archivesData['laiyuan'] : ''}`); //来源
      this.LODOP.ADD_PRINT_TEXT(187, 119, 150, 20, `${item.archivesData['chicun（cm）'] ? item.archivesData['chicun（cm）'] : ''}`); //尺寸
      this.LODOP.ADD_PRINT_TEXT(187, 332, 150, 20, `${item.archivesData['zhongliang（kg）'] ? item.archivesData['zhongliang（kg）'] : ''}`); //重量
      this.LODOP.ADD_PRINT_TEXT(187, 550, 135, 20, ""); //征集、发掘人
      this.LODOP.ADD_PRINT_TEXT(215, 119, 361, 20, ""); //款识
      this.LODOP.ADD_PRINT_TEXT(215, 534, 150, 20, `${item.archivesData['ruzangshijian'] ? item.archivesData['ruzangshijian'] : ''}`); //入藏日期
      this.LODOP.ADD_PRINT_TEXT(238, 119, 361, 122, `${item.archivesData['zangpinmiaoshu'] ? item.archivesData['zangpinmiaoshu'] : ''}`); //现状描述
      this.LODOP.ADD_PRINT_TEXT(240, 534, 155, 20, ""); //如何入藏
      this.LODOP.ADD_PRINT_TEXT(266, 534, 155, 20, `${item.archivesData['gourujiage（yuan）'] ? item.archivesData['gourujiage（yuan）'] : ''}`); //金额
      this.LODOP.ADD_PRINT_TEXT(292, 534, 155, 20, ""); //照片号
      this.LODOP.ADD_PRINT_TEXT(316, 553, 140, 20, ""); //有关文件号
      this.LODOP.ADD_PRINT_TEXT(369, 119, 366, 20, ""); //鉴定意见 (通过的评审意见)
      this.LODOP.ADD_PRINT_TEXT(341, 519, 175, 42, `${item.archivesData['beizhu'] ? item.archivesData['beizhu'] : ''}`); //备注
      if (type) {
        //直接打印
        var JOB_CODE = this.LODOP.PRINT()
        if (type == 1) {
          if (index + 1 == daNum && JOB_CODE) {
            if (this.reapetList.length) {
              this.$message.success(`${daNum}张藏品卡打印成功，${this.reapetList.length}个打印失败，未生成藏品卡`)
              this.reapetList = []
            } else {
              this.$message.success('打印成功')
            }
            this.getDataList('', 1)
          }
        } else {
          if (JOB_CODE) {
            this.$message.success('打印成功')
          }
        }
      }
    },

    //筛选树的数据
    filterTreeData() {
      console.log(this.filterText)
      this.$refs.tree.filter(this.filterText);
    },

    filterNode(value, data) {
      if (!value) return true;
      return data.archivesBasicName.indexOf(value) !== -1;
    },

    //表格勾选数据
    selectionChangeHandle(val) {
      this.dataListSelect = val
    },

    // 展览每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.getDataList(1, 1)
    },
    // 展览当前页
    currentChangeHandle(val) {
      this.pageNo = val;
      this.getDataList('', 2);
    },
  },
}
</script>

<style scoped>
.noData {
  line-height: calc(100vh - 154px);
}

.leftHeighe {
  height: calc(100vh - 124px);
  overflow-y: auto;
}

.treeDateCls {
  overflow-y: auto !important;
  height: calc(100% - 80px);
}

.table {
  .el-table__fixed-right,
  .el-table__fixed {
    height: auto !important;
    bottom: 17px !important;
  }
}

</style>
